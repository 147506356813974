@import "../style/global-styles.scss";

.things-to-do::before {
  position: absolute;
  top: 0;
  height: $pc-navbar-height-desktop;
  width: 100%;
  background-color: $pc-green;
  z-index: -1;
  content: "";
}

.things-to-do {
  background-color: $pc-green;
  font-family: "Calibre";

  .things-to-do-swiper {
    display: none;
  }

  .events {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .event {
      width: 100%;
      border: 2px solid white;

      img {
        width: 100%;
      }

      .text {
        padding: 20px;

        .title {
          color: white;
          font-family: "GT America";
          font-size: 3.33rem;
          font-weight: bold;
        }

        .description {
          font-size: 2.4rem;

          p {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .things-to-do {
    .things-to-do-swiper {
      display: block;
    }

    .events {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (min-width: 1024px) {
  .things-to-do {
    .things-to-do-swiper {
      display: block;
    }

    .events {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 50px;
      grid-column-gap: 50px;
    }
  }
}
