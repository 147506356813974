@import "../style/global-styles.scss";

.leasing-map {
  position: relative;
  font-family: "Calibre";

  g#Building_x5F_Red {
    cursor: pointer;
  }

  g#Buildings_x5F_Blue {
    cursor: pointer;
  }

  g#Building_x5F_Green {
    cursor: pointer;
  }

  g#Building_x5F_14 {
    cursor: pointer;
  }

  g#Building_x5F_15 {
    cursor: pointer;
  }

  .building-description {
    display: grid;
    grid-template-columns: 1fr;
    margin: 30px 0 10px 0;
    font-size: 1.5rem;

    .title {
      font-family: "GT America";
      font-size: 3rem;
      padding: 10px 10px 5px 10px;
      color: white;
    }

    .description {
      padding: 0 10px 0 10px;
      padding: 0 10px 5px 10px;
      color: white;
    }

    .building-swiper {
      height: 248px;

      .building-image {
        grid-row: 1 / 2;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .leasing-map {
    .building-description {
      display: none;
      position: absolute;
      font-size: 2.4rem;
      color: $pc-darkblue;
      width: 33%;
      padding: 15px;

      &.active {
        display: block;

        &.upper-right {
          top: 20px;
          right: 20px;
        }

        &.upper-left {
          top: 20px;
          left: 20px;
        }

        &.middle-left {
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
        }

        &.middle-right {
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }

        &.bottom-right {
          bottom: 20px;
          right: 20px;
        }

        &.bottom-left {
          bottom: 20px;
          left: 20px;
        }

        // &.background-macaroni {
        //   .description {
        //     color: $pc-darkblue;
        //   }
        // }
      }

      .title {
        font-family: "GT America";
        color: white;
        font-size: 3.6rem;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .building-swiper {
        .building-image {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
