@import "../style/global-styles.scss";

.piedmont-hero {
  font-family: "Calibre";
  position: relative;
  top: -40px;
  padding-top: 100px;

  .title {
    font-family: "GT America";
    font-weight: bold;
    font-size: 6rem;
    line-height: 1;
    color: white;
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
    z-index: 2;
    margin: -40px 0 0 0;

    p {
      margin: 0;
    }
  }

  .picture {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;

    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .piedmont-hero {
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    .title {
      font-size: 7rem;
      margin: 0;
    }

    .picture {
      img {
        width: 464px;
        height: auto;
        object-fit: contain;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .piedmont-hero {
    padding-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    .title {
      font-size: 10rem;
      margin: 0;
    }

    .picture {
      img {
        width: 584px;
        height: 559px;
        object-fit: contain;
      }
    }
  }
}
