@import "../style/global-styles.scss";

$mobile-spacing: 20px;

.footer {
  padding-top: 40px;
  background-color: $pc-darkblue;
  color: white;
  font-weight: normal;
  font-family: "Calibre";

  a {
    text-decoration: none;
    color: white;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 2rem;
    grid-row-gap: 20px;

    .icons {
      display: flex;
      align-items: flex-end;

      .icon {
        margin-right: 10px;
        font-size: 4rem;
      }
    }

    .button-container {
      display: flex;
      align-items: center;

      .maps-button {
        margin-top: $mobile-spacing;
        display: inline-block;
        box-sizing: border-box;
        border: 4px solid $pc-lightgray;
        padding: 5px 60px;
      }
    }

    .map-section {
      img {
        width: 100%;
      }
    }

    .address-section {
      color: white;

      strong {
        font-weight: bold;
        color: $pc-lightgray;
      }

      p {
        margin: 0;
      }
    }

    .links-section {
      margin-top: $mobile-spacing;
      font-weight: bold;
      font-family: "GT America";

      a {
        color: $pc-lightgray;
      }
    }

    .logo-section {
      margin-top: $mobile-spacing;
      align-self: center;
      width: 100%;
    }

    .copyright-section {
      display: flex;
      align-items: flex-end;
    }
  }

  .spacer {
    height: 75px;
  }
}

@media only screen and (min-width: 768px) {
  .footer {
    .grid {
      grid-template-columns: repeat(2, 1fr);

      .icons {
        grid-column: 1 / 3;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .footer {
    .header {
      color: white;
    }

    .grid {
      grid-template-columns: 1fr 1fr 470px;
      grid-row-gap: 10px;
      line-height: 1.267;

      .icons {
        grid-column: 1 / 3;
      }

      .button-container {
        margin: 0;
        grid-column: 3 / 4;
        grid-row: 1 / 2;

        .maps-button {
          margin: 0;
        }
      }

      .map-section {
        grid-column: 3 / 4;
        grid-row: 2 / span 3;

        img {
          width: 100%;
        }
      }

      .links-section {
        margin: 0;
      }

      .logo-section {
        margin: 0;
        grid-column: 1 / 3;
      }

      .copyright-section {
        grid-column: 1 / 2;
      }

      .wilbert-design {
        grid-row: 4 / 5;
        grid-column: 2 / 3;
        align-self: flex-end;
      }
    }
  }
}
