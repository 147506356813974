@import "../style/global-styles.scss";

.news {
  font-family: "Calibre";
  color: $pc-darkblue;

  .header {
    font-family: "GT America";
    font-weight: bold;
    font-size: 8.12rem;
    margin-bottom: 0;
  }

  .centered {
    text-align: center;
  }

  .button {
    background-color: white;
    border: 4px solid $pc-lightgray;
    font-family: "Calibre";
    font-size: 2rem;
    padding: 5px 60px;
    color: $pc-darkblue;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1024px) {
  .news {
    .press-release {
      font-size: 3.86rem;
    }
  }
}
