@import "../style/global-styles.scss";

.press-release {
  font-size: 3rem;
  color: $pc-darkblue;
  line-height: 1.1515;

  .subheader {
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
  }

  p {
    margin-top: 20px;
  }

  .press-release-button {
    margin-top: 50px;
  }
}
