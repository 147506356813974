@import "../style/global-styles.scss";

.piedmont-facts {
  font-family: "GT America";
  font-size: 1.2rem;
  overflow: hidden;
  display: none;

  .fact-container {
    text-align: center;
    align-items: center;
    width: 300%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    transition: transform 0.3s ease-out;

    &.first {
      grid-column: 1 / 2;
    }

    &.second {
      grid-column: 2 / 3;
    }

    &.third {
      grid-column: 3 / 4;
    }

    .fact {
      p {
        margin: 0;
      }

      &.red {
        color: $pc-red;
      }

      &.big {
        line-height: 1;

        strong {
          font-size: 7rem;
        }
      }

      strong {
        font-size: 3rem;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .piedmont-facts {
    display: block;
    font-size: 3.2rem;

    .smaller {
      font-size: 1.9rem;
    }

    .fact-container {
      .fact {
        &.big {
          line-height: 1;

          &.smaller {
            strong {
              font-size: 11rem !important;
            }
          }

          strong {
            font-size: 13rem;
          }
        }

        &.smaller {
          strong {
            font-size: 5rem !important;
          }
        }

        strong {
          font-size: 5rem;
        }
      }
    }
  }
}
