@import "../style/global-styles.scss";

.separator-line {
  margin: 30px 0 30px 0;
  height: 3px;
  width: 100%;

  &.thick {
    height: 6px;
  }

  &.darkblue {
    background-color: $pc-darkblue;
  }

  &.lightgray {
    background-color: $pc-lightgray;
  }
}
