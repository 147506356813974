@import "../style/global-styles.scss";

.piedmont-description {
  font-size: 2.6rem;
  color: white;

  &.dark-blue {
    color: $pc-darkblue;
  }
}

@media only screen and (min-width: 1024px) {
  .piedmont-description {
    font-size: 4rem;
  }
}
