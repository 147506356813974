@import "../style/global-styles.scss";

$nav-overlap: 50px;
$transition-time: 0.1s;

.navbar {
  font-family: "Calibre";
  height: $pc-navbar-height-mobile;
  user-select: none;
  transition: all $transition-time ease-in;
  position: relative;

  &.expanded {
    background-color: $pc-darkblue;
    z-index: 5;
  }

  .full-height {
    height: 100%;
  }

  .nav-section {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .logo {
      padding-top: 30px;
      position: relative;
      z-index: 10;
      display: inline-block;

      &.horizontal {
        width: 139px;
        height: 27px;
      }

      &.regular {
        width: 159px;
        height: 64px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .nav-button {
      align-self: flex-end;
      position: relative;
      top: $nav-overlap;
      margin-right: 27px;
      display: inline-block;
      height: calc(100% + #{$nav-overlap});
      width: 50px;
      background-color: $pc-lightgray;
      z-index: 3;
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 3.5em;
      padding-bottom: 10px;
      color: $pc-darkblue;
    }
  }

  #nav-links {
    position: relative;
    z-index: 1;
    height: 0px;
    overflow: hidden;
    transition: all $transition-time ease-in;

    &.expanded {
      height: auto;
      background-color: $pc-darkblue;

      .links {
        margin: 80px 0 50px 0;

        .link {
          font-family: "GT America";
          font-size: 4rem;
          text-decoration: none;
          color: $pc-lightgray;

          &:hover.white {
            color: white;
          }

          &:hover.red {
            color: $pc-red;
          }

          &:hover.green {
            color: $pc-green;
          }

          &:hover.blue {
            color: $pc-blue;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .navbar {
    height: $pc-navbar-height-desktop;

    .nav-section {
      .nav-button {
        margin-right: 87px;
        width: 80px;
        font-size: 5rem;
      }

      .logo {
        &.horizontal {
          width: 200px;
          height: 39px;
        }

        &.regular {
          width: 159px;
          height: 64px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    #nav-links.expanded {
      .links {
        .link {
          font-size: 5.2rem;
        }
      }
    }
  }
}
