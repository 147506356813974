@import "../style/global-styles.scss";

.press-release-page {
  .header {
    font-family: "GT America";
    font-weight: bold;
    font-size: 8.12rem;
    margin-bottom: 0;
  }
}
