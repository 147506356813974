@import "style/global-styles.scss";

html {
  font-size: 62.5%;

  img {
    vertical-align: middle;
  }

  .background-red {
    background-color: $pc-red;
    color: white;
  }

  .background-macaroni {
    background-color: $pc-macaroni;
    color: black;
  }

  .background-green {
    background-color: $pc-green;
    color: white;
  }

  .background-blue {
    background-color: $pc-blue;
    color: white;
  }

  .background-darkblue {
    background-color: $pc-darkblue;
    color: white;
  }

  .red {
    color: $pc-red;
  }

  .macaroni {
    color: $pc-macaroni;
  }

  .green {
    color: $pc-green;
  }

  .blue {
    color: $pc-blue;
  }

  .darkblue {
    color: $pc-darkblue;
  }
}

:root {
  --swiper-theme-color: white !important;
}
