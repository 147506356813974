@import "../style/global-styles.scss";

.about::before {
  position: absolute;
  top: 0;
  height: $pc-navbar-height-desktop;
  width: 100%;
  background-color: $pc-red;
  z-index: -1;
  content: "";
}

.about {
  background-color: $pc-red;
  font-family: "Calibre";
  color: white;

  .subtitle {
    font-family: "GT America";
    font-weight: bold;
    font-size: 2.6rem;
    color: $pc-darkblue;
  }

  .block-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    width: 100%;

    .item {
      width: 100%;

      .header {
        font-family: "GT America";
        font-size: 2.6rem;
      }

      .description {
        font-size: 2.2rem;

        p {
          margin-top: 10px;
        }
      }

      img {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
  .about {
    .subtitle {
      font-size: 4rem;
    }

    .block-section {
      grid-template-columns: repeat(2, 1fr);

      .item {
        .header {
          font-size: 4rem;
        }

        .description {
          font-size: 2.4rem;
        }
      }
    }
  }
}
