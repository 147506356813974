@import "../style/global-styles.scss";

.homepage {
  font-family: "Calibre";
  margin-top: 20px;

  .desktop {
    display: none;
  }

  .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .subtitle {
    font-family: "GT America";
    font-weight: bold;
    font-size: 2.6rem;
    color: $pc-darkblue;
  }

  .homepage-swiper-container {
    position: relative;
    padding-top: 56.25%;

    .homepage-swiper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .homepage-swiper > div {
      height: 100%;
    }
  }

  #feed {
    .title {
      color: $pc-macaroni;
      font-size: 4.4rem;
      font-family: "GT America";
      font-weight: bold;
    }

    .embed {
      width: 100%;

      .crt-grid-post {
        .crt-post-c {
          width: 100%;
        }
      }

      .crt-load-more {
        border: 4px solid $pc-lightgray;
        font-family: "Calibre";
        font-size: 2rem;
        font-weight: normal;
        color: $pc-darkblue;
        padding: 5px 60px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .homepage {
    .desktop {
      display: block;
    }

    .subtitle {
      font-size: 4rem;
    }
  }
}
