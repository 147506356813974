@font-face {
  font-family: "Calibre";
  font-weight: lighter;
  src: url("../fonts/Calibre-Light.otf");
}

@font-face {
  font-family: "Calibre";
  src: url("../fonts/calibre-web-regular.woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Calibre";
  src: url("../fonts/calibre-web-medium.woff2");
  font-weight: bold;
}

@font-face {
  font-family: "GT America";
  src: url("../fonts/GT-America-Standard-Bold.woff2");
  font-weight: bold;
}
