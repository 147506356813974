@import "../style/global-styles.scss";

.news-list {
  .news-entry {
    display: grid;
    grid-template-columns: 1fr;
    line-height: 1.222;

    .image {
      img {
        width: 100%;
      }
    }

    &.inverted {
      flex-direction: row-reverse;
      justify-content: space-between;

      .text {
        padding: 0 20px 0 0;
      }

      .blurb {
        color: $pc-green;
      }
    }

    .text {
      font-size: 2.4rem;
      margin-top: 20px;

      a {
        text-decoration: none;
        color: $pc-darkblue;
      }

      .news-header {
        font-weight: bold;
        text-transform: uppercase;
      }

      .blurb {
        font-size: 2rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .news-list {
    .news-entry {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 29px;

      &.inverted {
        .image {
          grid-column: 2 / span 1;
        }

        .text {
          grid-column: 1 / span 1;
        }
      }

      .image {
        grid-column: 1 / span 1;
        grid-row: 1;

        img {
          width: 100%;
        }
      }

      .text {
        grid-column: 2 / span 1;
        grid-row: 1;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .news-list {
    .news-entry {
      grid-template-columns: 0.7fr 1fr;

      &.inverted {
        grid-template-columns: 1fr 0.7fr;

        .image {
          grid-column: 2 / span 1;
        }

        .text {
          grid-column: 1 / span 1;
        }
      }

      .image {
        grid-column: 1 / span 1;
        grid-row: 1 / 2;
      }

      .text {
        font-size: 2.6rem;
        grid-column: 2 / span 1;
        grid-row: 1 / 2;

        .blurb {
          font-size: 3rem;
        }
      }
    }
  }
}
