@import "../style/global-styles.scss";

.leasing::before {
  position: absolute;
  top: 0;
  height: $pc-navbar-height-desktop;
  width: 100%;
  background-color: $pc-darkblue;
  z-index: -1;
  content: "";
}

.leasing {
  font-family: "Calibre";
  background-color: $pc-darkblue;
  color: white;

  .leasing-description {
    text-align: center;
  }

  .amenities {
    display: grid;
    text-align: center;

    .amenities-group {
      display: grid;
      grid-template-columns: 1fr;
    }

    .amenity {
      font-size: 2.4rem;
      margin-bottom: 1em;

      .title {
        font-weight: bold;

        &.red {
          color: $pc-red;
        }

        &.blue {
          color: $pc-blue;
        }

        &.green {
          color: $pc-green;
        }
      }

      .amenities-list ul {
        list-style: none;
        padding: 0;
        margin: 5px 0 0 0;

        li {
          line-height: 0.9;
          height: auto;
          padding-bottom: 8px;
        }
      }

      p {
        margin-top: 0;
      }
    }
  }

  .contact {
    display: flex;
    justify-content: center;
    font-size: 2.1rem;
    text-align: center;

    .email {
      font-family: "GT America";
      font-weight: bold;
      color: $pc-macaroni;
      font-size: 1.9rem;

      a {
        color: $pc-macaroni;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .leasing {
    .leasing-description {
      text-align: left;
    }

    .amenities {
      grid-template-columns: repeat(3, 1fr);
      text-align: left;
    }

    .contact {
      font-size: 3.86rem;

      .email {
        font-size: 3rem;
      }
    }
  }
}
