@import "../style/global-styles.scss";

.location::before {
  position: absolute;
  top: 0;
  height: $pc-navbar-height-desktop;
  width: 100%;
  background-color: $pc-blue;
  z-index: -1;
  content: "";
}

.location {
  font-family: "Calibre";
  background-color: $pc-blue;
  color: white;

  .desktop {
    display: none;
  }

  .blocks {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    font-size: 2.2rem;

    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .title {
      font-family: "GT America";
      color: white;
      font-size: 2.6rem;
    }

    p {
      margin-top: 10px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .location {
    .desktop {
      display: block;
    }

    .blocks {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 50px;
      font-size: 2.4rem;

      .title {
        font-size: 4rem;
      }
    }
  }
}
